import Typography from 'typography';
import theme from 'typography-theme-us-web-design-standards';

theme.baseFontSize = '16px';
theme.baseLineHeight = 1.5;
theme.scale = 2.25;
theme.bodyWeight = 400;
theme.googleFonts = [
  {
    name: 'Amatic SC',
    styles: ['400', '700'],
  },
  {
    name: 'Raleway',
    styles: ['300', '700'],
  },
];
theme.headerFontFamily = ['Amatic SC', 'sans-serif'];
theme.bodyFontFamily = ['Raleway', 'sans-serif'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
theme.overrideThemeStyles = ({ rhythm }, options, styles) => ({
  // h2: {
  //   fontFamily: 'Montserrat',
  // },
});

const typography = new Typography(theme);
const { rhythm } = typography;

const scale = (num: number) =>
  Object.entries(typography.scale(num))
    .map(
      ([k, v]) => `${k.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}: ${v}`
    )
    .join(';\n');

export { rhythm, scale, typography as default };
