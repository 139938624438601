import React from 'react';
import { Link } from 'gatsby';
import Scroll from 'react-scroll';
import styled from 'styled-components';

import { ReactComponent as LogoCrownImage } from 'images/logos/logo-crown.svg';
import { SocialMediaIcons } from 'components';
import { NavLinks } from 'types';
import { rhythm } from 'utils/typography';

interface Props {
  author: string;
  navLinks?: NavLinks[];
}

const Footer = styled.footer`
  align-items: center;
  background: ${props => props.theme.header.backgroundColor};
  box-shadow: 0 3px -10px -5px ${props => props.theme.header.boxShadowColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: 90rem;
  padding: ${rhythm(1)};
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  @media (min-width: 48em) {
    flex-direction: row;
    padding: ${rhythm(1)} ${rhythm(2)};
  }
`;

const Copyright = styled.p`
  color: #fff;
  margin: ${rhythm(1)} auto;

  @media (min-width: 48em) {
    margin: 0 0 0 auto;
  }
`;

const StyledLogoCrownImage = styled(LogoCrownImage)`
  order: 2;
  width: 2.5rem;

  & .cls-1 {
    fill: ${props => props.theme.header.logoFill};
    transition: fill 0.3s ease-in-out;
  }
`;

const Navigation = styled.nav`
  order: 2;

  @media (min-width: 48em) {
    margin: 0 0 0 auto;
    order: 3;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  -webkit-font-smoothing: antialiased; /* to stop flickering of text in safari */

  & li {
    list-style-type: none;
    margin: 0;
    padding: 0.5em 1em;

    & a {
      color: ${props => props.theme.nav.color};
      font-family: 'Amatic SC', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      text-decoration: none;
      text-transform: capitalize;

      &:focus {
        text-decoration: underline;
      }

      &:hover {
        color: ${props => props.theme.color.link.hover};
      }
    }
  }
`;

export default ({ author, navLinks }: Props) => (
  <Footer>
    {/* <StyledLogoCrownImage /> */}
    {/* <Navigation role="navigation">
      <Menu>
        {navLinks.map(link => (
          <li key={link.name}>
            {link.target ? (
              <a href={link.url} target={link.target}>
                {link.name}
              </a>
            ) : link.url.substr(0, 1) === '#' ? (
              <Scroll.Link
                to={link.url.substr(1)}
                spy
                smooth
                duration={500}
                offset={0}
              >
                {link.name}
              </Scroll.Link>
            ) : (
              <Link to={link.url}>{link.name}</Link>
            )}
          </li>
        ))}
      </Menu>
    </Navigation> */}
    <SocialMediaIcons />
    <Copyright>
      © {new Date().getFullYear()}, {author}
    </Copyright>
  </Footer>
);
