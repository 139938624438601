import React, { useRef } from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import styled from 'styled-components';

import { ScreenReadersOnly, SiteNavigation } from 'components';
import { ReactComponent as LogoCrownImage } from 'images/logos/logo-crown.svg';
// import { ReactComponent as LogoTextImage } from 'images/logos/logo-text.svg';
import { NavLinks } from 'types';
import { rhythm } from 'utils/typography';

interface Props {
  navLinks?: NavLinks[];
  siteTitle?: string;
}

const Header = styled.header`
  align-items: center;
  background: ${props => props.theme.header.backgroundColor};
  box-shadow: 0 3px 10px -5px ${props => props.theme.header.boxShadowColor};
  display: flex;
  padding: ${rhythm(1 / 2)} ${rhythm(2 / 3)};
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  @media (${props => props.theme.media.expandedNav}) {
    padding: ${rhythm(1 / 2)} ${rhythm(1)};
  }
`;

const Logo = styled.h1`
  flex-grow: 0;
  margin: 0;
  padding: 0;

  & a {
    align-items: center;
    display: flex;
  }

  & img {
    margin-bottom: 0;
  }
`;

const StyledLogoCrownImage = styled(LogoCrownImage)`
  margin-right: 1rem;
  width: 2.5rem;

  & .cls-1 {
    fill: ${props => props.theme.header.logoFill};
    transition: fill 0.3s ease-in-out;
  }
`;

// const StyledLogoTextImage = styled(LogoTextImage)`
//   width: 13rem;

//   & .cls-1 {
//     fill: ${props => props.theme.header.logoFill};
//     transition: fill 0.3s ease-in-out;
//   }
// `;

const LogoLink = styled(Link)`
  &:hover {
    ${StyledLogoCrownImage} {
      & .cls-1 {
        fill: ${props => props.theme.header.logoHoverFill};
      }
    }

    /* ${StyledLogoTextImage} {
      & .cls-1 {
        fill: ${props => props.theme.header.logoHoverFill};
      }
    } */
  }
`;

const NavigationContainer = styled.div`
  margin-left: auto;

  @media (${props => props.theme.media.expandedNav}) {
    flex: 1;
  }
`;

export default ({ navLinks, siteTitle = '' }: Props) => {
  const headroomRef = useRef(null);

  return (
    <Headroom disableInlineStyles ref={headroomRef}>
      <Header role="banner">
        <Logo>
          <LogoLink to="/">
            <StyledLogoCrownImage />
            {/* <StyledLogoTextImage aria-hidden="true" /> */}
            <ScreenReadersOnly>{siteTitle}</ScreenReadersOnly>
          </LogoLink>
        </Logo>
        <NavigationContainer>
          <SiteNavigation navLinks={navLinks} headroomRef={headroomRef} />
        </NavigationContainer>
      </Header>
    </Headroom>
  )
};
