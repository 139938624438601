import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {
  FaFacebook,
  // FaInstagram,
  FaTwitter,
  // FaYoutube,
} from 'react-icons/fa';
import styled from 'styled-components';

import { ScreenReadersOnly } from 'components';

const SocialMediaIcons = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  & > a {
    border-radius: 50%;
    background: ${props => props.theme.socialMediaIcons.backgroundColor};
    color: ${props => props.theme.socialMediaIcons.color};
    padding: 0.4375rem;
    margin: 0 0.375rem 0.375rem;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    & > svg {
      width: 1.5rem;
      height: 100%;
    }

    &:hover {
      background: ${props => props.theme.socialMediaIcons.hoverBackgroundColor};
      color: ${props => props.theme.socialMediaIcons.hoverColor};
      text-decoration: none;
    }
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            social {
              facebook
              # instagram
              twitter
              # youtube
            }
          }
        }
      }
    `}
    render={data => {
      const {
        facebook,
        // instagram,
        twitter,
        // youtube,
      } = data.site.siteMetadata.social;
      return (
        <SocialMediaIcons>
          <a href={`https://facebook.com/${facebook}`}>
            <ScreenReadersOnly>Facebook</ScreenReadersOnly>
            <FaFacebook aria-hidden="true" />
          </a>
          <a href={`https://twitter.com/${twitter}`}>
            <ScreenReadersOnly>Twitter</ScreenReadersOnly>
            <FaTwitter aria-hidden="true" />
          </a>
          {/* <a href={`https://instagram.com/${instagram}`}>
            <ScreenReadersOnly>Instagram</ScreenReadersOnly>
            <FaInstagram aria-hidden="true" />
          </a> */}
          {/* <a href={`https://www.youtube.com/channel/${youtube}`}>
            <ScreenReadersOnly>youtube</ScreenReadersOnly>
            <FaYoutube aria-hidden="true" />
          </a> */}
        </SocialMediaIcons>
      );
    }}
  />
);
