import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  body: {
    backgroundColor: '#aba4f1',
    color: '#595959', // rgba(0, 0, 0, 0.8)',
  },
  color: {
    heading: '#5d56c1',
    link: {
      normal: '#a353c4',
      hover: '#bf61e6',
    },
    separator: '#ccc',
    text: {
      subdued: '#727779',
    },
  },
  header: {
    backgroundColor: '#5d56c1',
    boxShadowColor: 'rgba(0, 0, 0, 0.2)',
    logoFill: '#fff',
    logoHoverFill: '#a353c4',
  },
  media: {
    expandedNav: 'min-width: 60em',
  },
  nav: {
    color: '#fff',
  },
  socialMediaIcons: {
    backgroundColor: 'transparent',
    color: 'white',
    hoverBackgroundColor: 'transparent',
    hoverColor: '#bf61e6',
  },
};

const darkTheme: DefaultTheme = {
  ...lightTheme,
  body: {
    backgroundColor: '#111',
    color: 'rgba(255, 255, 255, 0.5)',
  },
};

export { darkTheme, lightTheme };
